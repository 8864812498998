import clsx from 'clsx'
import React from 'react'
import type { HeaderResult } from '../data/content/HeaderFragment'
import s from './Header.module.sass'
import { Link } from './Link'

export type HeaderProps = {
	header: HeaderResult
}

export function Header(props: HeaderProps) {
	return (
		<>
			<div className={clsx(s.Header)}>
				{props.header.links.map((item, i) => (
					<div className={s.LinkWrapper} key={i}>
						{item.link && <Link link={item.link} className={s.Link} activeClassName={s.isActive} />}
					</div>
				))}
			</div>
		</>
	)
}
