import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { ContemberImage } from '../../ContemberImage'
import s from './Image.module.sass'

export function Image({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Photo}>
			<div className={s.ImageBox}>
				<div className={s.Image}>
					{reference.image && (
						<ContemberImage image={reference.image} layout="fill" objectFit="cover" />
					)}
				</div>
				<p className={s.ImageInfo}>{reference.text}</p>
			</div>
		</div>
	)
}
