import * as React from 'react'

const SvgArrowForward = () => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 14.476c5.878 0 11.54-1.016 17.474-.478 4.1.372 6.063 1.739 10.182.478M12.719 3c3.132 1.945 6.084 4.276 9.479 5.738 3.274 1.41 5.657 5.147 8.802 6.216m-1.875.478c-4.433 3.7-7.798 7.722-11.145 12.379-.728 1.012-2.245 1.578-3.386 1.966"
			stroke="#C30000"
			strokeWidth={2}
			strokeLinecap="round"
		/>
	</svg>
)

export default SvgArrowForward
