import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { ContemberImage } from '../../ContemberImage'
import s from './CardMedia.module.sass'

export function CardMedia({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.CardMedia}>
			<div className={clsx(s.Box, s.ImageWrapper)}>
				<div className={s.Image}>
					{reference.cardMedia?.image && <ContemberImage image={reference.cardMedia.image} />}
				</div>
				<p className={s.ImageNote}>{reference.cardMedia?.imageNote}</p>
			</div>
			<div className={clsx(s.Box, s.Text)}>
				{reference.cardMedia?.text && <RichTextRenderer source={reference.cardMedia.text} />}
			</div>
		</div>
	)
}
