import React from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import s from './Footer.module.sass'
import { Gallery } from './Gallery'

export type FooterProps = {
	footer: FooterResult
}
export function Footer(props: FooterProps) {
	return (
		<>
			<div className={s.Footer}>
				<p className={s.Support}>{props.footer.projectSupport}</p>
				<div className={s.Gallery}>
					{props.footer?.gallery && <Gallery gallery={props.footer.gallery} />}
				</div>

				<p className={s.Auspices}>{props.footer.projectAuspices}</p>

				<div className={s.Mangoweb}>
					Vývoj: <a href="https://www.mangoweb.cz/">manGoweb</a>
				</div>
			</div>
		</>
	)
}
