import NextImage from 'next/image'
import React from 'react'

const _storageUrlFragmentBeta = 'https://s3.eu-central-1.amazonaws.com/api.contember.mgw.cz/'
const _storageUrlFragment = 'https://s3.eu-central-1.amazonaws.com/api.contember.mangoweb.cz/'

const _betaCdn = 'https://contember-beta.imgix.net/'
const _prodCdn = 'https://contember.imgix.net/'

export const Image: typeof NextImage = (props) => {
	const { src } = props

	const srcProps = React.useMemo(() => {
		if (typeof src === 'string') {
			const isGif = /\.gif$/.test(src)
			if (!isGif) {
				return { src }
			}
			return {
				src,
				unoptimized: true,
			}
		}
		return {
			src,
		}
	}, [src])

	const combined = { ...props, ...srcProps } as React.ComponentProps<typeof NextImage>

	combined.unoptimized = process.env.NODE_ENV === 'development' ? true : combined.unoptimized

	return <NextImage {...combined} />
}
