import type { SourcePageResult } from '../data/content/SourcePageFragment'
import { ArrowLink } from './ArrowLink'
import { Content } from './Content'
import { Seo } from './Seo'
import s from './SourcePage.module.sass'

export type SourceProps = {
	sourcePage: SourcePageResult
}

export function SourcePage(props: SourceProps) {
	return (
		<>
			<Content>
				<div className={s.SourcePage}>
					<h1 className={s.Title}>{props.sourcePage.title}</h1>
					<div className={s.Links}>
						{props.sourcePage.links.map((item, id) => (
							<div key={id} className={s.LinkWrapper}>
								<p className={s.LinkNote}>{item.title}</p>
								{item.link && <ArrowLink link={item.link} />}
							</div>
						))}
					</div>
				</div>
			</Content>
			{props.sourcePage.seo && <Seo seo={props.sourcePage.seo} />}
		</>
	)
}
