import * as React from 'react'

const SvgArrowBack = () => (
	<svg width={33} height={29} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M31.602 16.3c-5.996 0-11.771 1.017-17.825.478-4.183-.371-6.184-1.738-10.387-.478m16.258 11.476c-3.195-1.945-6.207-4.276-9.67-5.738-3.34-1.41-5.77-5.146-8.978-6.216m1.912-.477c4.522-3.7 7.955-7.723 11.37-12.38.742-1.012 2.29-1.577 3.453-1.965"
			stroke="#C14545"
			strokeWidth={2}
			strokeLinecap="round"
		/>
	</svg>
)

export default SvgArrowBack
