import { RichTextRenderer } from '@contember/react-client'
import type { TimelinePageResult } from '../data/content/TimelinePageFragment'
import type { TimelinePostResult } from '../data/content/TimelinePostFragment'
import { Content } from './Content'
import { Seo } from './Seo'
import s from './TimelinePage.module.sass'
import { TimelinePost } from './TimelinePost'

export type TimelineProps = {
	timelinePage: TimelinePageResult
	timelinePosts: TimelinePostResult[]
}

export function TimelinePage(props: TimelineProps) {
	return (
		<>
			<Content>
				<div className={s.TimelinePage}>
					<h1 className={s.Title}>
						{props.timelinePage.title && <RichTextRenderer source={props.timelinePage.title} />}
					</h1>
					{props.timelinePosts && <TimelinePost timelinePost={props.timelinePosts} />}
				</div>
			</Content>
			{props.timelinePage.seo && <Seo seo={props.timelinePage.seo} />}
		</>
	)
}
