/* eslint-disable @next/next/next-script-for-ga */
import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { Event } from '../app/components/Event'
import { FrontPage } from '../app/components/FrontPage'
import { Layout } from '../app/components/Layout'
import { Page } from '../app/components/Page'
import { SourcePage } from '../app/components/SourcePage'
import { SpecialEvent } from '../app/components/SpecialEvent'
import { TimelinePage } from '../app/components/TimelinePage'
import { ActiveUrlContext } from '../app/contexts/ActiveUrlContext'
import { combineUrl } from '../app/data/combineUrl'
import { EventFragment } from '../app/data/content/EventFragment'
import { EventTileFragment } from '../app/data/content/EventTileFragment'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { FrontPageFragment } from '../app/data/content/FrontPageFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { PageFragment } from '../app/data/content/PageFragment'
import { SourcePageFragment } from '../app/data/content/SourcePageFragment'
import { SpecialEventFragment } from '../app/data/content/SpecialEventFragment'
import { TimelinePageFragment } from '../app/data/content/TimelinePageFragment'
import { TimelinePostFragment } from '../app/data/content/TimelinePostFragment'
import { VenueFragment } from '../app/data/content/VenueFragment'
import { One, OrderDirection, Thunder } from '../generated/content'
import { RespondWithNotFound, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const activeUrl = props.data.getLinkable?.url ?? null
	const { page, frontPage, sourcePage, specialEvent, timelinePage, event } =
		props.data.getLinkable ?? {}
	const header = props.data.getHeader
	const footer = props.data.getFooter
	const frontPageEvents = props.data.listEvent
	const timelinePosts = props.data.listTimelinePost
	const venues = props.data.listVenue

	return (
		<ActiveUrlContext.Provider value={activeUrl}>
			<Layout header={header} footer={footer}>
				<Head>
					<title>1942</title>
					<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
					<link rel="manifest" href="/site.webmanifest" />
					<meta name="msapplication-TileColor" content="#ecece7" />
					<meta name="theme-color" content="#ffffff" />
					{/* Global site tag (gtag.js) - Google Analytics */}
					<script async src="https://www.googletagmanager.com/gtag/js?id=G-MFHPT0HB2X"></script>
					<script
						dangerouslySetInnerHTML={{
							__html: `
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());

						gtag('config', 'G-MFHPT0HB2X');
						`,
						}}
					/>
				</Head>
				{event && <Event event={event} />}
				{page && <Page page={page} />}
				{frontPage && <FrontPage frontPage={frontPage} events={frontPageEvents} venues={venues} />}
				{sourcePage && <SourcePage sourcePage={sourcePage} />}
				{specialEvent && <SpecialEvent specialEvent={specialEvent} />}
				{timelinePage && <TimelinePage timelinePage={timelinePage} timelinePosts={timelinePosts} />}

				{/* <section>
				<h1>
				Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
				</section>

				<section>
				<h1>
				Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
			</Layout>
		</ActiveUrlContext.Provider>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const data = await content.query({
		// ...SiteFragment(urlInfo.locale),
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderFragment(),
		],
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				page: [{}, PageFragment()],
				frontPage: [{}, FrontPageFragment()],
				timelinePage: [{}, TimelinePageFragment()],
				specialEvent: [{}, SpecialEventFragment()],
				sourcePage: [{}, SourcePageFragment()],
				event: [{}, EventFragment()],
			},
		],
		listEvent: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			EventTileFragment(),
		],
		listTimelinePost: [
			{
				orderBy: [{ order: OrderDirection.asc }],
			},
			TimelinePostFragment(),
		],
		listVenue: [{ orderBy: [{ order: OrderDirection.asc }] }, VenueFragment()],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterFragment(),
		],
	})

	if (
		!data.getLinkable?.page &&
		!data.getLinkable?.frontPage &&
		!data.getLinkable?.timelinePage &&
		!data.getLinkable?.specialEvent &&
		!data.getLinkable?.sourcePage &&
		!data.getLinkable?.event
	) {
		throw new RespondWithNotFound('Blog post not found')
	}

	return {
		props: {
			url,
			urlInfo,
			data,
			serverTime: new Date().toString(),
		},
	}
})
