import type { GalleryResult } from '../data/content/GalleryFragment'
import { ContemberImage } from './ContemberImage'
import s from './Gallery.module.sass'

export type GalleryProps = {
	gallery: GalleryResult
}

export function Gallery(props: GalleryProps) {
	return (
		<>
			{props.gallery?.items && (
				<div className={s.Gallery}>
					{props.gallery.items.map((item, id) => (
						<div className={s.Image} key={id}>
							{item.image && <ContemberImage image={item.image} />}
						</div>
					))}
				</div>
			)}
		</>
	)
}
