import SvgArrowForward from '../svg/icons/ArrowForward'
import s from './ArrowLink.module.sass'
import { Link, type LinkProps } from './Link'

export function ArrowLink(props: LinkProps) {
	return (
		<Link
			{...props}
			className={s.ArrowLink}
			append={
				<span className={s.Arrow}>
					<SvgArrowForward />
				</span>
			}
		/>
	)
}
