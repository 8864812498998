import type { EventTileResult } from '../data/content/EventTileFragment'
import type { VenueResult } from '../data/content/VenueFragment'
import { ContemberImage } from './ContemberImage'
import s from './EventTileList.module.sass'

export type EventTileListProps = {
	events: EventTileResult[]
	venues?: VenueResult[]
}

export function EventTileList(props: EventTileListProps) {
	return (
		<div className={s.Events}>
			{props.events.map((event) => (
				<div key={event.id} className={s.Event}>
					<a href={event.link?.url} className={s.Link}>
						<div className={s.ImageWrapper}>
							<div className={s.Image}>
								{event.image && (
									<ContemberImage image={event.image} layout="fill" objectFit="cover" />
								)}
							</div>
						</div>
						<p className={s.Date}>{event.date}</p>
						<h3 className={s.Title}>{event.title}</h3>
					</a>
					<div className={s.EventLabels}>
						{event.eventLabels.map((eventLabel) => (
							<p key={eventLabel.id} className={s.EventLabel}>
								{eventLabel.text}
							</p>
						))}
					</div>
				</div>
			))}
		</div>
	)
}
