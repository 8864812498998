import clsx from 'clsx'
import React, { useState } from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import type { HeaderResult } from '../data/content/HeaderFragment'
import SvgMenu from '../svg/sprites/Menu'
import useOnClickOutside from '../utils/useOnClickOutside'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Footer } from './Footer'
import { Header } from './Header'
import s from './Layout.module.sass'

export type LayoutProps = {
	header?: HeaderResult
	footer?: FooterResult
	children: React.ReactNode
}

export function Layout(props: LayoutProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()

	React.useEffect(() => {
		setOpen(false)
	}, [loading])

	const ref = React.useRef(null)

	const handleClickOutside = () => {
		setOpen(false)
	}

	useOnClickOutside(ref, handleClickOutside)

	return (
		<div className={s.Layout}>
			{/* <HidingHeader> */}
			<div className={clsx(s.MobileHeader, open && s.isActive)}>
				<button className={s.MobileHeaderButton} onClick={() => setOpen(true)}>
					<SvgMenu />
				</button>

				<div className={s.MobileHeaderContent} ref={ref}>
					{props.header && <Header header={props.header} />}
				</div>
			</div>
			{/* </HidingHeader> */}
			<div className={s.Header}>{props.header && <Header header={props.header} />}</div>
			{props.children}
			{props.footer && (
				<div className={s.Footer}>
					<Footer footer={props.footer} />
				</div>
			)}
		</div>
	)
}
