import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { SpecialEventResult } from '../data/content/SpecialEventFragment'
import { BlockRenderer } from './blocks/BlockRenderer'
import { ContemberImage } from './ContemberImage'
import { Content } from './Content'
import { Seo } from './Seo'
import s from './SpecialEvent.module.sass'

export type SpecialProps = {
	specialEvent: SpecialEventResult
}

export function SpecialEvent(props: SpecialProps) {
	return (
		<>
			<Content>
				<div className={s.SpecialEvent}>
					<h1 className={s.Title}>{props.specialEvent.title}</h1>
					<div className={s.Wrapper}>
						<div className={s.Box}>
							<div className={s.Subtitle}>
								{props.specialEvent.subtitle && (
									<RichTextRenderer source={props.specialEvent.subtitle} />
								)}
							</div>
							<div className={s.Image}>
								{props.specialEvent.image && <ContemberImage image={props.specialEvent.image} />}
								<p>{props.specialEvent.imageNote}</p>
							</div>
						</div>
						<div className={clsx(s.OpeningText, s.Box)}>
							{props.specialEvent.openingText && (
								<RichTextRenderer source={props.specialEvent.openingText} />
							)}
						</div>
					</div>
					<div className={s.ContentEditor}>
						<BlockRenderer blocks={props.specialEvent.content?.blocks ?? []} />
					</div>
				</div>
			</Content>
			{props.specialEvent.seo && <Seo seo={props.specialEvent.seo} />}
		</>
	)
}
