import type { TimelinePostResult } from '../data/content/TimelinePostFragment'
import { ArrowLink } from './ArrowLink'
import { ContemberImage } from './ContemberImage'
import s from './TimelinePost.module.sass'

export type TimelineProps = {
	timelinePost: TimelinePostResult[]
}

export function TimelinePost(props: TimelineProps) {
	return (
		<>
			<div className={s.TimelinePosts}>
				{props.timelinePost.map((item, i) => (
					<div key={i} className={s.TimelinePost}>
						<div className={s.Box}>
							<h3 className={s.Title}>{item.title}</h3>
							<p className={s.Text}>{item.text}</p>
						</div>

						<div className={s.Border}>
							<p className={s.Date}>{item.date}</p>
						</div>
						<div className={s.Box}>
							{item.image && <ContemberImage image={item.image} />}
							<p className={s.AdditionalInfo}>{item.additionalInfo}</p>
							{item.link && <ArrowLink link={item.link} />}
						</div>
					</div>
				))}
			</div>
		</>
	)
}
