import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import type { LinkResult } from '../data/content/LinkFragment'

export type LinkProps = {
	className?: string
	activeClassName?: string
	gradient?: boolean
	down?: boolean
	nonExternal?: boolean
	children?: React.ReactNode
	append?: React.ReactNode
} & ({ link: LinkResult } | { href: string })

export function Link(props: LinkProps) {
	const activeUrl = useActivePage()

	if ('href' in props) {
		const isActive = activeUrl === props.href
		return (
			<NextLink href={props.href}>
				<a
					className={clsx(props.className, isActive && props.activeClassName)}
					target={!props.nonExternal ? '_blank' : ''}>
					{props.children}
					{props.append}
				</a>
			</NextLink>
		)
	}
	if (props.link?.externalLink || props.link?.internalLink) {
		const external = Boolean(props.link.type === LinkType.external)
		const url = (
			props.link.internalLink?.url ? props.link.internalLink.url : props.link.externalLink
		) as string

		const isActive = activeUrl === url
		return (
			<NextLink href={url}>
				<a
					className={clsx(props.className, isActive && props.activeClassName)}
					target={external && !props.nonExternal ? '_blank' : ''}>
					{props.children ?? props.link.title}
					{props.append}
				</a>
			</NextLink>
		)
	} else {
		return null
	}
}
