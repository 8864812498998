import clsx from 'clsx'
import s from './Content.module.sass'

export type ContentProps = {
	hero?: React.ReactNode
	heroFill?: boolean
	children: React.ReactNode
}

export function Content(props: ContentProps) {
	return (
		<>
			{props.hero && (
				<div className={clsx(s.heroFill, props.heroFill && s.isFill)}>{props.hero}</div>
			)}
			<div></div>
			<div className={s.Main}>{props.children}</div>
		</>
	)
}
