import clsx from 'clsx'
import type { FrontPageResult } from '../data/content/FrontPageFragment'
import { ContemberImage } from './ContemberImage'
import { Gallery } from './Gallery'
import s from './Hero.module.sass'

export type HeroProps = Pick<
	FrontPageResult,
	'title' | 'image' | 'projectSupport' | 'gallery' | 'projectAuspices'
>

export function Hero(props: HeroProps) {
	return (
		<div className={s.Hero}>
			<div className={s.TitleBox}>
				<h1 className={s.Title}>
					{props.title?.split('').map((letter, i) => (
						<span key={i} className={s.Letter}>
							{letter}
						</span>
					))}
				</h1>
				<div className={s.Flag}>
					{props.image && (
						<ContemberImage
							image={props.image}
							layout="fill"
							objectFit="contain"
							objectPosition="center bottom"
						/>
					)}
				</div>
			</div>
			<div className={s.Supporters}>
				<p className={clsx(s.Text, s.Support)}>{props.projectSupport}</p>
				{props.gallery && <Gallery gallery={props.gallery} />}
				<p className={clsx(s.Text, s.Auspices)}>{props.projectAuspices}</p>
			</div>
		</div>
	)
}
