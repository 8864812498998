import type { EventTileResult } from '../data/content/EventTileFragment'
import type { FrontPageResult } from '../data/content/FrontPageFragment'
import type { VenueResult } from '../data/content/VenueFragment'
import { Content } from './Content'
import { EventTileList } from './EventTileList'
import { EventVenues } from './EventVenues'
import s from './FrontPage.module.sass'
import { Hero } from './Hero'
import { Seo } from './Seo'
import { TextBoxes } from './TextBoxes'

export type FrontProps = {
	frontPage: FrontPageResult
	events: EventTileResult[]
	venues: VenueResult[]
}

export function FrontPage(props: FrontProps) {
	return (
		<>
			<Content
				hero={
					<Hero
						title={props.frontPage.title}
						image={props.frontPage.image}
						projectSupport={props.frontPage.projectSupport}
						gallery={props.frontPage.gallery}
						projectAuspices={props.frontPage.projectAuspices}
					/>
				}>
				<div className={s.FrontPage}>
					<TextBoxes
						subtitle={props.frontPage.subtitle}
						openingText={props.frontPage.openingText}
						firstLink={props.frontPage.firstLink}
						secondLink={props.frontPage.secondLink}
					/>
					<h3 id="Events" className={s.EventsTitle}>
						{props.frontPage.eventsTitle}
					</h3>
					<EventVenues
						venues={props.venues}
						events={props.events.filter((event) => !event.pastEvent)}
					/>
					<h3 className={s.EventsTitle}>Již proběhlé akce</h3>
					<EventTileList events={props.events.filter((event) => event.pastEvent)} />
				</div>
			</Content>
			{props.frontPage.seo && <Seo seo={props.frontPage.seo} />}
		</>
	)
}
