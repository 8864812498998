import clsx from 'clsx'
import { useState } from 'react'
import type { EventTileResult } from '../data/content/EventTileFragment'
import type { VenueResult } from '../data/content/VenueFragment'
import { EventTileList } from './EventTileList'
import s from './EventVenues.module.sass'

export type EventVenuesProps = {
	events: EventTileResult[]
	venues: VenueResult[]
}

export function EventVenues(props: EventVenuesProps) {
	const [activeVenue, setActiveVenue] = useState<null | string>(null)

	return (
		<>
			<div className={s.Filters}>
				<button
					className={clsx(s.Filter, activeVenue === null && s.isActive)}
					onClick={(e) => {
						e.preventDefault()
						setActiveVenue(null)
					}}>
					Vše
				</button>
				{props.venues.map((item, i) => (
					<div key={i}>
						<button
							className={clsx(s.Filter, activeVenue === item.id && s.isActive)}
							onClick={(e) => {
								e.preventDefault()
								setActiveVenue(item.id)
							}}>
							{item.name}
						</button>
					</div>
				))}
			</div>
			<div className={s.Events}>
				<EventTileList
					venues={props.venues}
					events={props.events.filter((event) => {
						return !activeVenue || activeVenue === event.venue?.id
					})}
				/>
			</div>
		</>
	)
}
