import { useRouter } from 'next/dist/client/router'
import React from 'react'

export function usePageLoadingState() {
	const { asPath, events } = useRouter()

	const [loading, setLoading] = React.useState(false)
	const [duration, setDuration] = React.useState(0)

	React.useEffect(() => {
		const handleStart = () => {
			setLoading(true)
		}
		const handleComplete = () => {
			setLoading(false)
		}

		events.on('routeChangeStart', handleStart)
		events.on('routeChangeComplete', handleComplete)
		events.on('routeChangeError', handleComplete)

		return () => {
			events.off('routeChangeStart', handleStart)
			events.off('routeChangeComplete', handleComplete)
			events.off('routeChangeError', handleComplete)
		}
	}, [asPath, events])

	React.useEffect(() => {
		if (loading) {
			const start = new Date().getTime()
			const interval = setInterval(() => {
				setDuration(new Date().getTime() - start)
			}, 100)

			return () => clearInterval(interval)
		} else {
			setDuration(0)
		}
	}, [loading])

	return [loading, duration] as const
}
