import type { EventResult } from '../data/content/EventFragment'
import SvgArrowBack from '../svg/sprites/ArrowBack'
import { BlockRenderer } from './blocks/BlockRenderer'
import { ContemberImage } from './ContemberImage'
import { Content } from './Content'
import s from './Event.module.sass'
import { Link } from './Link'
import { Seo } from './Seo'

export type EventProps = {
	event: EventResult
}

export function Event(props: EventProps) {
	return (
		<>
			<Content heroFill hero={<h1 className={s.Title}>{props.event.title}</h1>}>
				<div className={s.Event}>
					<div className={s.EventInfoWrapper}>
						<div>
							{props.event.image && (
								<div className={s.Image}>
									<ContemberImage image={props.event.image} />
								</div>
							)}
							<div className={s.ContentEditorWrapper}>
								<BlockRenderer
									className={s.ContentEditor}
									blocks={props.event.content?.blocks ?? []}
								/>
							</div>
						</div>
						<div className={s.EventInfo}>
							<div className={s.InfoBox}>
								<p className={s.InfoLabel}>Datum a čas:</p>
								<p className={s.InfoValue}>{props.event.date}</p>
							</div>
							<div className={s.InfoBox}>
								<p className={s.InfoLabel}>Místo:</p>
								<p className={s.InfoValue}>{props.event.place}</p>
							</div>
							<div className={s.InfoBox}>
								<p className={s.InfoLabel}>Vstupné:</p>
								<p className={s.InfoValue}>{props.event.entrance}</p>
							</div>
							<div className={s.InfoBox}>
								<p className={s.InfoLabel}>Odkaz na akci:</p>
								{props.event.linkForEvent && (
									<Link link={props.event.linkForEvent} className={s.InfoValue} />
								)}
							</div>
						</div>
					</div>

					<div className={s.BackButtonWrapper}>
						<SvgArrowBack />
						<a href="/#Events" className={s.BackButton}>
							Zpět na akce
						</a>
					</div>
				</div>
			</Content>
			{props.event.seo && <Seo seo={props.event.seo} />}
		</>
	)
}
