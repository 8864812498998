import type { PageResult } from '../data/content/PageFragment'
import { BlockRenderer } from './blocks/BlockRenderer'
import { Content } from './Content'
import s from './Page.module.sass'
import { Seo } from './Seo'

export type PageProps = {
	page: PageResult
}

export function Page(props: PageProps) {
	return (
		<>
			<Content>
				<div className={s.Page}>
					<h1 className={s.Title}>{props.page.title}</h1>
					<div className={s.Content}>
						<BlockRenderer className={s.ContentEditor} blocks={props.page.content?.blocks ?? []} />
					</div>
				</div>
			</Content>
			{props.page.seo && <Seo seo={props.page.seo} />}
		</>
	)
}
