import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FrontPageResult } from '../data/content/FrontPageFragment'
import { ArrowLink } from './ArrowLink'
import s from './TextBoxes.module.sass'

export type TextBoxesProps = Pick<
	FrontPageResult,
	'subtitle' | 'openingText' | 'firstLink' | 'secondLink'
>

export function TextBoxes(props: TextBoxesProps) {
	return (
		<div className={s.TextBoxes}>
			<div className={clsx(s.TextBox, s.Subtitle)}>
				{props.subtitle && <RichTextRenderer source={props.subtitle} />}
			</div>
			<div className={s.TextBox}>
				<div className={s.OpeningText}>
					{props.openingText && <RichTextRenderer source={props.openingText} />}
				</div>
				<div className={s.Links}>
					{props.firstLink && <ArrowLink link={props.firstLink} />}
					{props.secondLink && <ArrowLink link={props.secondLink} />}
				</div>
			</div>
		</div>
	)
}
