import { NextSeo } from 'next-seo'
import type { SeoResult } from '../data/content/SeoFragment'

export type SeoProps = {
	seo: SeoResult
}

export function Seo(props: SeoProps) {
	return (
		<NextSeo
			title={props.seo?.title}
			description={props.seo?.description}
			openGraph={{
				title: props.seo.ogTitle,
				description: props.seo.ogDescription,
				images: props.seo?.ogImage?.url
					? [
							{
								url: props.seo.ogImage.url,
								width: props.seo.ogImage.width,
								height: props.seo.ogImage.height,
								alt: props.seo.ogImage.alt,
								type: props.seo.ogImage.type,
							},
					  ]
					: [],
			}}
		/>
	)
}
